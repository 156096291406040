import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../cards/menu_card.scss';

function MenuItemCard({ item, onEdit, onDelete }) {
    return (
        <Card 
            className="menu-item-card" 
            variant="outlined" 
            style={{
                backgroundColor: '#f9f9f9',
                border: '1px solid #ddd',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                margin: '16px',
                padding: '12px',
            }}
        >
            <CardContent>
               
               
                <Typography 
                    variant="h5" 
                    component="h2" 
                    style={{ color: '#333', fontWeight: 'bold', marginBottom: '8px' }}
                >
                    {item.menu_name}
                </Typography>

           
           
                <Typography 
                    variant="subtitle1" 
                    color="textSecondary" 
                    component="p" 
                    style={{ marginBottom: '4px', fontSize: '1rem', color: '#555' }}
                >
                    Price: <strong style={{ color: '#1976d2' }}>Rs {item.price}</strong>
                </Typography>

               
               
                <Typography 
                    variant="subtitle2" 
                    color="textSecondary" 
                    component="p" 
                    style={{ marginBottom: '8px', fontSize: '0.9rem', color: '#757575' }}
                >
                    CGST: <span style={{ color: '#388e3c' }}>{item.cgst}%</span>, SGST: <span style={{ color: '#388e3c' }}>{item.sgst}%</span>
                </Typography>

              
              
                <div 
                    style={{ 
                        display: 'flex', 
                        gap: '16px', 
                        alignItems: 'center', 
                        marginBottom: '16px', 
                        flexWrap: 'wrap' 
                    }}
                >
                    {item.code && (
                        <Typography 
                            variant="subtitle2" 
                            style={{ fontSize: '0.9rem', color: '#616161' }}
                        >
                            Code: <strong>{item.code}</strong>
                        </Typography>
                    )}
                    {item.section && (
                        <Typography 
                            variant="subtitle2" 
                            style={{ fontSize: '0.9rem', color: '#616161' }}
                        >
                            Section: <strong>{item.section}</strong>
                        </Typography>
                    )}
                </div>

               
               
                <div 
                    className="button-container" 
                    style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}
                >
                    <IconButton 
                        onClick={onEdit} 
                        aria-label="edit" 
                        className="edit-button" 
                        style={{ color: '#1976d2' }}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton 
                        onClick={onDelete} 
                        aria-label="delete" 
                        className="delete-button" 
                        style={{ color: '#d32f2f' }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            </CardContent>
        </Card>
    );
}

export default MenuItemCard;
